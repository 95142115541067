import useAnalytics from './useAnalytics';

const usePixalEvent = () => {
	const { fbEvent, firebaseEvent, claverTapEvent } = useAnalytics();

	const page_view = (payload) => {
		// fbEvent('ViewContent', {
		// 	screen_name: payload,

		// });
		firebaseEvent('page_view', {
			screen_name: payload
		});
		claverTapEvent('page_view', {
			screen_name: payload
		});
	};
	const EventAddToCart = (payload) => {
		const { items, value } = payload;

		fbEvent('AddToCart', {
			content_ids: [items.p_id.toString()], //Array of productIds
			content_name: items.p_name, //String
			content_type: 'product', //String
			contents: [
				{
					id: items.p_id.toString(),
					quantity: items.qty,
					...items
				}
			], //Array of objects
			currency: 'BDT', //String
			value: value //Number
		});
		firebaseEvent('add_to_cart', {
			currency: 'BDT',
			items: [
				{
					item_id: payload.items.p_id,
					item_name: payload.items.p_name,
					price: payload.value
				}
			],
			value: payload.value
		});
		claverTapEvent('ACT_ADD_TO_CART', {
			product_id: payload.items.p_id,
			product_category_id: payload.items.p_product_category_id,
			product_company_id: payload.items.p_brand_id,
			product_name: payload.items.p_name,
			product_price: payload.value,
			product_currency: 'BDT',
			product_quantity: payload.items.qty
		});
	};

	const EventViewContent = (payload) => {
		const { items, value } = payload;
		firebaseEvent('view_item', {
			currency: 'BDT',
			items: [
				{
					item_id: payload.items?.p_id,
					item_name: payload.items?.p_name,
					price: payload?.value
				}
			],
			value: payload.value
		});

		fbEvent('view_item', {
			content_ids: [items?.p_id?.toString()], //Array of productIds
			content_category: items?.p_product_category_id?.toString(), //String
			content_name: items?.p_name, //String
			content_type: 'product',
			contents: [
				{
					...items,
					quantity: 1
				}
			],
			currency: 'BDT',
			value: value
		});

		claverTapEvent('ACT_VIEW_PRODUCT', {
			product_id: items?.p_id,
			product_category_id: items?.p_product_category_id,
			product_company_id: items?.p_brand_id,
			product_name: items?.p_name,
			product_price: value,
			product_currency: 'BDT'
		});
	};

	const EventSearch = (payload) => {
		fbEvent('Search', {
			search_string: payload.search
		});
		firebaseEvent('search', {
			search_term: payload.search
		});
		claverTapEvent('ACT_SEARCH_TERM', {
			search_term: payload.search
		});
	};

	const EventBeginCheckout = (payload) => {
		const { items, value, contents } = payload;
		fbEvent('InitiateCheckout', {
			content_category: 'Product',
			content_ids: contents?.map((e) => e.p_id.toString()),
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			content_type: 'product',
			currency: 'BDT',
			num_items: contents?.length,
			value: value
		});
		firebaseEvent('begin_checkout', {
			currency: 'BDT',
			items: items,
			coupon: payload.coupon,
			value: value
		});
		claverTapEvent('ACT_BEGIN_CHECKOUT', {
			items: JSON.stringify(items),
			address: JSON.stringify(payload?.address)
		});
	};

	const EventPurchase = (payload) => {
		const { items, value, contents } = payload;
		fbEvent('Purchase', {
			content_category: 'Product',
			content_ids: contents?.map((e) => e.p_id.toString()),
			contents: contents?.map((e) => {
				return { ...e, id: e.p_id, quantity: e.qty };
			}),
			content_type: 'product',
			currency: 'BDT',
			num_items: contents?.length,
			value: value
		});
		firebaseEvent('purchase', {
			currency: 'BDT',
			items: items,
			coupon: payload.coupon,
			value: value
		});
		claverTapEvent('ACT_CHECKOUT_SUCCESS', {
			items: JSON.stringify(items),
			address: JSON.stringify(payload?.address)
		});
	};
	return {
		page_view,
		EventAddToCart,
		EventViewContent,
		EventSearch,
		EventBeginCheckout,
		EventPurchase
	};
};

export default usePixalEvent;
