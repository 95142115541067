import v1_adminMeds from './icons/v1_adminMeds';
import v1_arrowdropdown from './icons/v1_arrowdropdown';
import v1_bd from './icons/v1_bd';
import v1_cart from './icons/v1_cart';
import v1_check from './icons/v1_check';
import v1_chevron_left from './icons/v1_chevron_left';
import v1_chevron_right from './icons/v1_chevron_right';
import v1_cold from './icons/v1_cold';
import v1_desc from './icons/v1_desc';
import v1_empty_cart from './icons/v1_empty_cart';
import v1_forward from './icons/v1_forward';
import v1_labtest from './icons/v1_labtest';
import v1_offer from './icons/v1_offer';
import v1_order_approve from './icons/v1_order_approve';
import v1_play from './icons/v1_play';
import v1_prescriptions from './icons/v1_prescriptions';
import v1_rx from './icons/v1_rx';
import v1_taka from './icons/v1_taka';
import v1_alcohol from './icons/v1_alcohol';
import v1_pregnancy from './icons/v1_pregnancy';
import v1_breastfeeding from './icons/v1_breastfeeding';
import v1_driving from './icons/v1_driving';
import v1_kidney from './icons/v1_kidney';
import v1_liver from './icons/v1_liver';
import v1_logo from './icons/v1_logo';
import v1_dhaka_city from './icons/v1_dhaka_city';
import v1_medicine_order from './icons/v1_medicine_order';
import v1_pres from './icons/v1_pres';
import v1_lab from './icons/v1_lab';
import v1_express from './icons/v1_express';
import v1_regular from './icons/v1_regular';
import v1_delete from './icons/v1_delete';
import v1_generic from './icons/v1_generic';
import v1_share from './icons/v1_share';
import v1_notified from './icons/v1_notified';
import v1_reffer from './icons/v1_reffer';
import v1_notified_block from './icons/v1_notified_block';
import v1_logo_bigger from './icons/v1_logo_bigger';

export {
	v1_adminMeds as IC_ADMIN_MEDS,
	v1_arrowdropdown as IC_ARROWDROPDOWN,
	v1_bd as IC_BD,
	v1_cart as IC_CART,
	v1_check as IC_CHECK,
	v1_chevron_left as IC_CHEVRON_LEFT,
	v1_chevron_right as IC_CHEVRON_RIGHT,
	v1_cold as IC_COLD,
	v1_desc as IC_DESC,
	v1_empty_cart as IC_EMPTY_CART,
	v1_forward as IC_FORWARD,
	v1_labtest as IC_LABTEST,
	v1_offer as IC_OFFER,
	v1_order_approve as IC_ORDER_APPROVE,
	v1_play as IC_PLAY,
	v1_prescriptions as IC_PRESCRIPTIONS,
	v1_rx as IC_RX,
	v1_taka as IC_TAKA,
	v1_alcohol as IC_ALCOHOL,
	v1_pregnancy as IC_PREGNANCY,
	v1_breastfeeding as IC_BREASTFEEDING,
	v1_driving as IC_DRIVING,
	v1_kidney as IC_KIDNEY,
	v1_liver as IC_LIVER,
	v1_logo as IC_LOGO,
	v1_dhaka_city as IC_Dhaka_City,
	v1_medicine_order as IC_MEDICINE_ORDER,
	v1_pres as IC_PRES,
	v1_lab as IC_LAB,
	v1_express as IC_EXPRESS,
	v1_regular as IC_REGULAR,
	v1_delete as IC_DELETE,
	v1_generic as IC_GENERIC,
	v1_share as IC_SHARE,
	v1_notified as IC_NOTIFIED,
	v1_reffer as IC_REFFER,
	v1_notified_block as IC_NOTIFIED_BLOCK,
	v1_logo_bigger as IC_LOGO_BIGGER
};
