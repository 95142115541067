'use client';

import { logEvent } from 'firebase/analytics';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { PIXELID } from '../apis/config';
import { firebaseAnalytics } from './firebase';

const useAnalytics = () => {
	const pathname = usePathname();

	useEffect(() => {
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init(PIXELID); // facebookPixelId
				ReactPixel.pageView();
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, [pathname]);

	const fbEvent = (eventName: string, eventParams: any) => {
		import('react-facebook-pixel')
			.then((x) => x.default)
			.then((ReactPixel) => {
				ReactPixel.init(PIXELID); // facebookPixelId
				ReactPixel.track(eventName, eventParams);
			});
	};

	const firebaseEvent = (eventName: string, eventParams: any) => {
		logEvent(firebaseAnalytics, eventName, eventParams);
	};

	const claverTapEvent = (eventName: string, eventParams: Record<string, any>) => {
		if (typeof window !== 'undefined' && window.clevertap) {
			window.clevertap.event.push(eventName, eventParams);
		} else {
			console.warn('CleverTap is not initialized');
		}
	};

	return { fbEvent, firebaseEvent, claverTapEvent };
};

export default useAnalytics;
